import type { IUser } from "~/solidJs/shared/helpers/types/IUser";
import { createContext, createSignal } from "solid-js";
import type { SuccessProps } from "~/solidJs/features/PromoCodeFeature/PromoCodeFeature";
export const [getUserStore, setUserStore] = createSignal<IUser | null | undefined>();
export const [getUserEmailVerificationStore, setUserEmailVerificationStore] = createSignal<{
	email: string;
} | null>(null);

export const UserContext = createContext<typeof getUserStore>();
export const [isPremiumModalShown, setIsPremiumModalShown] = createSignal<
	false | { source: string; unsubscribe?: boolean; promocode?: SuccessProps; isRuCards?: boolean }
>(false);
