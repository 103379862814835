import { isAxiosError } from "axios";
import { TaskBannedErrors, TaskLimitErrors } from "../models/errors/apiErrors";
export class BaseApi {
	static baseUrl = "api/";
	static baseUrlV2 = "api/v2";
}
export class ApiErrorHandlers {
	static LimitExceededError = "The launch limit has been exceeded";
	static handleLimitError(e: unknown) {
		if (isAxiosError(e)) {
			const code = e.response?.data?.detail?.code;
			const code2 = e.response?.data?.data;
			if (
				e.response?.status === 400 &&
				(code === TaskLimitErrors.MidjourneyLimit ||
					code === TaskLimitErrors.GeneralTaskLimit ||
					code2 === TaskLimitErrors.MidjourneyRateLimit)
			) {
				throw this.LimitExceededError;
			}
		}
	}
	static throwMessageFromError(e: unknown) {
		if (isAxiosError(e)) {
			const code = e.response?.data?.detail?.message;
			if (e.response?.status === 400 && !!code) {
				throw code;
			}
		}
	}
	static handleBannedWordsError(e: unknown) {
		if (isAxiosError(e)) {
			const code = e.response?.data?.detail;

			if (e.response?.status === 400 && code === TaskBannedErrors.ObsceneWords) {
				throw "Your prompt contains banned words";
			}
		}
	}
	static handleBannedWordsAndLimitError(e: unknown) {
		this.handleLimitError(e);
		this.handleBannedWordsError(e);
		this.throwMessageFromError(e);
	}
}
