import type { Params, RouteDataFunc } from "@solidjs/router";
import { newIdenifyEvent, sendIdentifyEvent } from "../amplitudeHelpers";
import type { IUser } from "../types";
import { type AuthRouterContext, routerConfig } from "~/solidJs/app/MainApp/models";

export interface ParseFeatureFlagLink {
	userProperty: string;
	userValue: string;
	to?: string;
}

export const parseFeatureFlagLink = (params: Params): ParseFeatureFlagLink | null => {
	const userProperty = params.flag;
	const userValue = params.variant;
	if (!userProperty || !userValue) {
		return null;
	}
	const to = params.to;
	return { userProperty, userValue, to: to || undefined };
};

export const addFlagToUserProperties = async (flag: ParseFeatureFlagLink, user: IUser) => {
	await sendIdentifyEvent(newIdenifyEvent({ [flag.userProperty]: flag.userValue }), user).promise;
};

export const routerDataFunc: RouteDataFunc<AuthRouterContext> = async (props) => {
	try {
		const user = await props.data.user;
		const parsedFlag = parseFeatureFlagLink(props.location.query);
		if (!parsedFlag) {
			props.navigate(routerConfig.workspace.absolute, { replace: true });
			return;
		}
		await addFlagToUserProperties(parsedFlag, user);
		if (parsedFlag.to) {
			props.navigate(parsedFlag.to, { replace: true });
			return;
		}
		props.navigate(routerConfig.workspace.absolute, { replace: true });
	} catch {
		props.navigate(routerConfig.workspace.absolute, { replace: true });
	}
};
