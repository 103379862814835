import { getUserStore, setUserStore } from "~/solidJs/entities/HeaderMain/models";
import { type IUser, UserOnlineInformation, UserSubscriptionPlanEnum } from "./types";
import { UserApi } from "../api/user";
import { getCookie } from "./cookiesHelpers";
import { PaymentApi } from "../api/PaymentApi";
import EventAnalytics, { GrayLog } from "~/js/utilities/EventAnalytics";
import { getPrivateMessageFromError } from "./errorHelpers";
import { config } from "~/js/network/network-handler";
import { DebugFlags, foundDiffBetweenTwoPrimitiveObjects, isDebugFlag, type primitives } from "..";
import type { Navigator } from "@solidjs/router";
import type { FilterObjectByValue } from "./types/ObjectHelpersTypes";
import { generationCounter } from "../models/user/methods";
import { createEffect } from "solid-js";
import { signOut } from "supertokens-web-js/lib/build/recipe/thirdpartyemailpassword";
import { addPrefixToKeys } from "~/js/utilities/addPrefixToKeys";
import type { Types as AmplitudeTypes } from "@amplitude/analytics-browser";
import { newIdenifyEvent, sendIdentifyEvent } from "./amplitudeHelpers";
export const getIsAccesDisabled = (...disabledGroups: UserSubscriptionPlanEnum[]) => {
	const group = getUserStore()?.group;

	if (!group) return true;
	for (const disabledGroup of disabledGroups) {
		if (group === disabledGroup) return true;
	}
	return false;
};
export function isEmailVerified(tokenPayload?: Record<string, any> | null) {
	return tokenPayload?.["st-ev"]?.v;
}

export async function getUserInfo(): Promise<IUser | null> {
	const refreshToken = getCookie("st-refresh-token");
	if (refreshToken === null) {
		return null;
	}
	const session = await UserApi.getUserInitInfo();
	if (!session) return null;

	return session;
}

export async function getUserOnlineInfo(): Promise<UserOnlineInformation | null> {
	const refreshToken = getCookie("st-refresh-token");
	if (refreshToken === null) {
		return null;
	}
	const session = await UserApi.getUserOnlineInfo();
	if (!session) return null;

	return session;
}

export async function getUserAndSetStore() {
	const user = await getUserInfo();
	setUserStore(user);
	return user;
}

export async function revalidateUserOnlineInfo() {
	const userOnline = await getUserOnlineInfo();
	if (!userOnline) return;
	setUserStore((prev) => {
		if (!prev) return userOnline;
		return { ...prev, ...userOnline };
	});
	return userOnline;
}

export function isUserInited(user?: IUser) {
	return user && !!user.id;
}

export function createRevalidateUserOnlineInfoInterval() {
	let interval: NodeJS.Timeout;
	let lastRevalidation = 0;

	const onRevalidateOnlineInfo = async () => {
		if (Date.now() - lastRevalidation < 10000) {
			return;
		}
		lastRevalidation = Date.now();
		await revalidateUserOnlineInfo();
	};

	const start = () => {
		interval = setInterval(onRevalidateOnlineInfo, 10000);
		window.addEventListener("focus", onRevalidateOnlineInfo);
	};

	const stop = () => {
		clearInterval(interval);
		window.removeEventListener("focus", onRevalidateOnlineInfo);
	};

	return { start, stop };
}

export function isAuthenticated(user?: IUser | null): boolean {
	return !!user;
}

export function verifyUser() {
	setUserStore((prev) => {
		if (!prev) return prev;
		prev["st-ev"] = {
			v: true,
			t: Date.now()
		};
	});
}

export function isUserNeedToSetupAccount(user?: IUser | null): boolean {
	return (
		isDebugFlag(DebugFlags.SetupAccontDebug) ||
		(!!user && (user.questionnaire === null || user.questionnaire === false))
	);
}

export function isUserHasAccess(
	user: IUser | undefined | null,
	accessMap: Record<string, boolean>
): boolean {
	if (!user) {
		return false;
	}
	return user.group in accessMap;
}
/**
 * @description gets payments from the server and send info to analitycs system
 */
export async function registratePaymentsInAnalitycs() {
	if (config?.IS_PRODUCTION !== "true") {
		return;
	}
	let payments: Awaited<ReturnType<typeof PaymentApi.getUserPayments>> | undefined;
	try {
		payments = await PaymentApi.getUserPayments();
	} catch (e) {
		console.error(e);
		EventAnalytics.sendErrorInfo("get-payments-error", {
			message: getPrivateMessageFromError(e)
		});
		return;
	}
	if (!payments?.length) {
		return;
	}
	try {
		for (const payment of payments) {
			try {
				GrayLog.debug({
					log_name: "purchase",
					message: "send purchase event",
					isNeedToSendToAnalitics: false,
					price: payment.price,
					transaction_id: payment.transaction_id,
					code: payment.discount_code
				});
			} catch (e) {}
			EventAnalytics.purchace(payment.price, payment.transaction_id, payment.discount_code);
		}
	} catch (e) {
		console.error(e);
		EventAnalytics.sendErrorInfo("send-payments-error", {
			message: getPrivateMessageFromError(e)
		});
	}
}

export const isUserFree = (user?: IUser | null) => {
	return (
		user?.group === UserSubscriptionPlanEnum.Freemium ||
		user?.group === UserSubscriptionPlanEnum.Trialing
	);
};

export const getUserGenerationsAmount = (user: Partial<IUser> | null) => {
	if (!user || !user.available_runs || !user.total_runs) return 0;
	return user.available_runs;
};

export const getIsUserHasGenerations = async () => {
	const onlineInfo = await revalidateUserOnlineInfo();
	if (!onlineInfo) return false;
	return getUserGenerationsAmount(onlineInfo) > 0;
};

const OAuthRestoreLocationKey = "OAuthRestoreLocationKey";

export const captureRedirectAuthLocation = () => {
	const url = new URL(location.href);
	const redirectTo = url.searchParams.get("from");
	if (!redirectTo) {
		localStorage.removeItem(OAuthRestoreLocationKey);
		return;
	}
	localStorage.setItem(OAuthRestoreLocationKey, redirectTo);
};

export const resotreRedirectAuthLocation = (navigate: Navigator) => {
	let out = false;
	const redirectTo = localStorage.getItem(OAuthRestoreLocationKey);
	if (redirectTo) {
		navigate(redirectTo, { replace: true });
		out = true;
	}

	localStorage.removeItem(OAuthRestoreLocationKey);
	return out;
};

const disabledPropertiesLookUp = {
	status: true,
	start_time: true,
	end_time: true,
	"st-ev": true,
	subscription: true,
	group: true,
	next_payment: true
} as const satisfies Partial<Record<keyof IUser, boolean>>;

export const getNonDisabledUserFields = (user: IUser) => {
	const record = {} as FilterObjectByValue<IUser, primitives>;
	for (const key in user) {
		if (key in disabledPropertiesLookUp) {
			continue;
		}
		// @ts-ignore
		record[key] = user[key];
	}
	return record;
};

/**
 * Remember last user info in local storage
 * if there is no user, return last remembered user
 */
export const rememberDangerousUser = (user?: IUser): IUser | null => {
	if (!user) {
		const userFromLocalStorage = localStorage.getItem("user");
		if (userFromLocalStorage) {
			try {
				const parsedUser = JSON.parse(userFromLocalStorage);
				if (parsedUser && typeof parsedUser === "object") {
					return parsedUser as IUser;
				}
			} catch (error) {}
		}
		return null;
	}
	localStorage.setItem("user", JSON.stringify(user));

	return user;
};

export const getAmplitudeReadyObject = <ObjT extends Record<string, primitive>>(
	obj: ObjT
): FilterObjectByValue<ObjT, AmplitudeTypes.ValidPropertyType> => {
	const out = {} as FilterObjectByValue<ObjT, AmplitudeTypes.ValidPropertyType>;
	for (const key in obj) {
		const value = obj[key];
		if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
			// @ts-ignore
			out[key] = value;
		}
	}
	return out;
};

export const updateGenerationCounter = (user: FilterObjectByValue<IUser, primitives>) => {
	if (user.total_runs === undefined || user.available_runs === undefined) return;
	const runs = user.total_runs - user.available_runs;
	if (!generationCounter.inited) {
		generationCounter.setCurCounter(runs);
		return;
	}
	generationCounter.updatePrefixes(runs);
};

export const createUserIdenifyEffect = () => {
	createEffect((prev: FilterObjectByValue<IUser, primitives> | undefined | null) => {
		const user = getUserStore();
		if (!user) return user;
		const primitveUser = getNonDisabledUserFields(user);
		if (primitveUser.banned_at) {
			signOut();
			document.querySelector("#banned-banner")?.classList?.remove("hidden");
			return;
		}
		updateGenerationCounter(primitveUser);
		const prefixedUser = addPrefixToKeys(primitveUser, "user_");
		let identifyEvent: AmplitudeTypes.Identify;
		if (prev) {
			const diff = foundDiffBetweenTwoPrimitiveObjects(
				addPrefixToKeys(prev, "user_"),
				prefixedUser
			);
			if (diff.length < 1) return primitveUser;
			identifyEvent = newIdenifyEvent(getAmplitudeReadyObject(Object.fromEntries(diff)));
			if (config.FRONT_CANARY) {
				identifyEvent.set("isCanary", config.FRONT_CANARY);
			}
		} else {
			identifyEvent = newIdenifyEvent(getAmplitudeReadyObject(prefixedUser));
			if (config.FRONT_CANARY) {
				identifyEvent.set("isCanary", config.FRONT_CANARY);
			}
		}
		sendIdentifyEvent(identifyEvent, user);
		if (config.IS_PRODUCTION !== "true") {
			console.log("send-idenify", identifyEvent);
		}

		return primitveUser;
	});
};
