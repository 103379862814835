import { createSignal } from "solid-js";
import type { IUser } from "~/solidJs/shared/helpers/types";
import {
	Experiment,
	type Variants,
	type ExperimentClient,
	type ExposureTrackingProvider,
	type Exposure
} from "@amplitude/experiment-js-client";
import { config } from "~/js/network/network-handler";
import * as amplitude from "@amplitude/analytics-browser";
let experiment: ExperimentClient;

class ExposureProvider implements ExposureTrackingProvider {
	track(exposure: Exposure): void {
		// amplitude.track("$exposure", exposure);
	}
}

export const initAmplitudeExperiment = () => {
	experiment = Experiment.initializeWithAmplitudeAnalytics(
		config.IS_PRODUCTION === "true"
			? "client-M46lTfiL0haWkpdkabAzdCgJuOX8tdDx"
			: "client-JoaE97urED3c5hrcNKLi1JkWlBDEejxE",
		{
			automaticExposureTracking: true,
			exposureTrackingProvider: new ExposureProvider()
		}
	);
};

const [getVariantsStore, setVariantsStore] = createSignal<Variants>({});

export enum ExperimentsKeys {
	Test = "dev-session-counter-flag",
	PricingV2 = "pricing-v2",
	AiConfPromo = "ai-conf-promo",
	ImportFiles = "import-files"
}

export const fetchExpreriment = async (user?: IUser): Promise<Variants> => {
	if (!user) {
		await experiment.fetch();
		return experiment.all();
	}
	await experiment.fetch({
		user_id: user.id,
		user_properties: {
			user_group: user.group,
			user_name: user.group
		}
	});

	return experiment.all();
};

export const fetchAndSetVariants = async (user?: IUser) => {
	const variants = await fetchExpreriment(user);
	setVariantsStore(variants);
};

export const getVariantValue = (key: ExperimentsKeys) => {
	const stored = getVariantsStore()[key] || null;
	experiment.variant(key);
	return stored || null;
};

export const getVariantPayload = <PayloadType>(key: ExperimentsKeys): PayloadType | undefined => {
	return getVariantValue(key)?.payload;
};

export const isVariantActive = (key: ExperimentsKeys) => {
	const item = getVariantValue(key);
	return item && item.key !== "off" && item.key !== "custom-off";
};
