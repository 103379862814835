import { createContext } from "solid-js";
import type Eventor from "~/js/utilities/Eventor";

export interface VersionResponse {
	BUILD_VERSION: string;
}

export interface VersionInfo {
	MAJOR: number;
	MINOR: number;
	PATCH: number;
}

export enum VersionEvents {
	MinorUpdate = "minorUpdate",
	MajorUpdate = "majorUpdate"
}

export interface VersonContextType {
	eventor: Eventor;
}

export const VersionContext = createContext<VersonContextType>();
