import type { Component, JSX } from "solid-js";
import { createAxiosClient } from "~/js/network/axiosClient";
import { initAmpli } from "~/js/utilities/EventAnalytics";
import { VersionUi } from "../versionHelpers";

type AxiosClientHocProps = {
	children: JSX.Element;
};

export const AxiosClientHoc: Component<AxiosClientHocProps> = (props) => {
	createAxiosClient();
	initAmpli();

	return <VersionUi.VersionProvider>{props.children}</VersionUi.VersionProvider>;
};
