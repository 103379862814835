import { splitProps, type ComponentProps, type JSX } from "solid-js";
import classes from "~/solidJs/entities/SolidModalView/ui/LayoutSolidModalView.module.scss";
import CrossIcon from "~/Icons/crossIcon.svg?component-solid";
import CloseIcon from "~/Icons/16_Icon_Close.svg?component-solid";
import { Button } from "~/solidJs/shared";
import { twJoin } from "tailwind-merge";
type CloseBtnProps = {} & ComponentProps<"button">;
export const CloseBtn = (props: CloseBtnProps) => (
	<Button onclick={props.onClick} class={twJoin("absolute right-4 top-4 p-1", props.class)}>
		<CrossIcon class="size-4 text-white" />
	</Button>
);
export const CloseBtnV2 = (props: CloseBtnProps) => {
	const [_, rest] = splitProps(props, ["class"]);
	return (
		<Button variant="AccentIcon" class={twJoin("p-2", props.class)} {...rest}>
			<CloseIcon class="text-white" />
		</Button>
	);
};
