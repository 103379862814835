import type { RouteDataFunc } from "@solidjs/router";
import { verifyEmail } from "supertokens-web-js/recipe/emailverification";
import EventAnalytics from "~/js/utilities/EventAnalytics";
import { verifyUser } from "~/solidJs/shared/helpers/userHelpers";
import { getPrivateMessageFromError } from "~/solidJs/shared/helpers/errorHelpers";
export const verifyEmailDataFc: RouteDataFunc = async (props) => {
	EventAnalytics.verifyEmailEvent();
	verifyEmail()
		.then((val) => {
			if (val?.status !== "OK") {
				EventAnalytics.sendErrorInfo("ss_email_verification_error", {
					message: val?.status
				});
				verifyUser();
			}
		})
		.catch((err) => {
			EventAnalytics.sendErrorInfo("ss_email_verification_error", {
				message: getPrivateMessageFromError(err)
			});
		})
		.finally(() => props.navigate("/", { replace: true }));
};
