import { type Component, type JSX, Match, Suspense, Switch } from "solid-js";
import classes from "./SuspenseBlick.module.scss";
import {
	Spinner,
	type spinnerVariants,
	type spinnerSizes
} from "~/solidJs/shared/ui/atoms/Spinner/Spinner";

type SuspenseBlockProps = {
	children: JSX.Element;
	variant?: spinnerVariants;
	size?: spinnerSizes;
	dark?: boolean;
	fallback?: JSX.Element;
	name?: string;
};
const Skeleton: Component<{ children: JSX.Element }> = (props) => {
	return <div class={classes.skeleton}>{props.children}</div>;
};
export const SuspenseBlock: Component<SuspenseBlockProps> = (props) => {
	return (
		<Suspense
			fallback={
				<Skeleton>
					{props.fallback || <Spinner variant={props.variant} size={props.size} />}
				</Skeleton>
			}
		>
			{props.children}
		</Suspense>
	);
};
type CustomSuspenseBlockProps = {
	isLoading: boolean;
	children: JSX.Element;
	dark?: boolean;
	className?: string;
	fallback?: JSX.Element;
	isFallback?: boolean;
};
export const CustomSuspenseBlock: Component<CustomSuspenseBlockProps> = (props) => {
	return (
		<Switch>
			<Match when={props.isLoading}>
				{props.isFallback ? (
					props.fallback
				) : (
					<Skeleton>
						<Spinner class={props.dark ? classes.spinner : ""} />
					</Skeleton>
				)}
			</Match>
			<Match when={!props.isLoading}>{props.children}</Match>
		</Switch>
	);
};
