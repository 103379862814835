import type { IEventorListners, IListnerFc } from "~/js/types/IEventorListners";

/**
 * Add Fc listener to class object???
 */
export default class Eventor {
	listeners: IEventorListners = {};

	invoke(eventName: string, ...args: any) {
		if (this.listeners[eventName])
			this.listeners[eventName].forEach((listener) => listener(...args));
	}

	addListener(eventName: string, listener: IListnerFc) {
		if (!this.listeners[eventName]) {
			this.listeners[eventName] = [];
		}
		this.listeners[eventName].push(listener);
	}

	removeListener(eventName: string, listener: IListnerFc) {
		const listenerIndex = this.listeners[eventName].indexOf(listener);
		if (listenerIndex > -1) {
			this.listeners[eventName].splice(listenerIndex, 1);
		}
	}

	clearListenersByEventName(eventName: string) {
		this.listeners[eventName] = [];
	}
}
