import type { Component, JSXElement } from "solid-js";

/**
 * Props for the Modal component
 */
export type ModalProps = {
	children: JSXElement;
};

/**
 * Base modal component that renders content in a white rounded container
 * Stops event propagation to prevent clicks from reaching the overlay
 */
export const Modal: Component<ModalProps> = (props) => {
	return (
		<article onClick={(e) => e.stopPropagation()} class="rounded-2xl w-[460px] bg-white">
			{props.children}
		</article>
	);
};

/**
 * Props for the ContentContainerText component
 */
type ContentContainerTextProps = {
	children: JSXElement;
};

/**
 * Container component for text content in modals
 * Provides consistent padding and spacing for text-based content
 */
export const ContentContainerText: Component<ContentContainerTextProps> = (props) => {
	return <div class="flex flex-col items-start px-5 py-4 gap-3 h-40">{props.children}</div>;
};

/**
 * Props for the ContentContainerInput component
 */
type ContentContainerInputProps = {
	children: JSXElement;
};

/**
 * Container component for input fields in modals
 * Provides consistent padding and spacing for input-based content
 */
export const ContentContainerInput: Component<ContentContainerInputProps> = (props) => {
	return <div class="flex flex-col items-start px-5 py-4 gap-5 h-40">{props.children}</div>;
};

/**
 * Props for the ButtonContainer component
 */
type ButtonContainerProps = {
	children: JSXElement;
};

/**
 * Container component for modal buttons
 * Renders buttons in a footer section with a top border and consistent spacing
 */
export const ButtonContainer: Component<ButtonContainerProps> = (props) => {
	return (
		<div class="flex border-t border-border-secondary p-4 gap-2 justify-end">
			{props.children}
		</div>
	);
};

/**
 * Props for the ModalOverlay component
 */
type ModalOverlayProps = {
	/** When true, clicking the overlay will close the modal */
	/** Optional callback function to close the modal */
	/** Content to be rendered inside the overlay */
	autoClose?: boolean;
	toggleSelf?: () => void;
	children: JSXElement;
};

/**
 * Semi-transparent overlay component that covers the entire viewport
 * Can be configured to close the modal when clicked
 */
export const ModalOverlay: Component<ModalOverlayProps> = (props) => {
	return (
		<div
			class="fixed z-[101] w-full h-full top-0 left-0 bg-[black]/80 flex items-center justify-center"
			onClick={() => {
				if (props.autoClose) {
					props.toggleSelf?.();
				}
			}}
		>
			{props.children}
		</div>
	);
};

/**
 * Convenience component that combines Modal and ModalOverlay
 * Provides a complete modal solution with overlay and content container
 */
export const ModalWithOverlayBoilerPlate: Component<ModalProps & ModalOverlayProps> = (props) => {
	return (
		<ModalOverlay autoClose={props.autoClose} toggleSelf={props.toggleSelf}>
			<Modal>{props.children}</Modal>
		</ModalOverlay>
	);
};
