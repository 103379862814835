import { onCleanup, onMount, type Component, type JSXElement } from "solid-js";
import Eventor from "~/js/utilities/Eventor";
import { VersionModel, VersionMethods } from "./";
import { config } from "~/js/network/network-handler";

export const VersionProvider: Component<{ children: JSXElement }> = (props) => {
	const eventor = new Eventor();

	const methods = VersionMethods.createCheckVersionInterval({
		onMajorUpdate: () => eventor.invoke(VersionModel.VersionEvents.MajorUpdate),
		onMinorUpdate: () => eventor.invoke(VersionModel.VersionEvents.MinorUpdate),
		currentVersion: config.PROJECT_VERSION
	});

	onMount(() => {
		methods?.startInterval();
	});

	onCleanup(() => {
		methods?.stopInterval();
	});

	return (
		<VersionModel.VersionContext.Provider
			value={{
				eventor
			}}
		>
			{props.children}
		</VersionModel.VersionContext.Provider>
	);
};
