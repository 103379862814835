import { VersionApi } from "./api";
import type { VersionInfo } from "./model";

const SEMVER_REGEX = /^\d+\.\d+\.\d+$/;

/**
 * Checks if a version string follows semantic versioning format (x.y.z)
 */
const isValidVersion = (version: string): boolean => {
	return SEMVER_REGEX.test(version);
};

export const parseVersion = (version: string): VersionInfo => {
	if (!isValidVersion(version)) {
		throw new Error("Invalid version format. Expected format: x.y.z");
	}

	const [major, minor, patch] = version.split(".").map(Number);
	return { MAJOR: major, MINOR: minor, PATCH: patch };
};

/**
 * Compares two semantic versions to determine if there's a major version update
 * @param currentVersion - Current semantic version (x.y.z)
 * @param latestVersion - Latest semantic version (x.y.z)
 * @returns boolean indicating if there's a major version update
 * @throws Error if versions are invalid
 */
export const isNewMajorUpdate = (
	currentVersion: VersionInfo,
	latestVersion: VersionInfo
): boolean => {
	return currentVersion.MAJOR < latestVersion.MAJOR;
};

/**
 * Compares two semantic versions to determine if there's a minor version update
 * @param currentVersion - Current semantic version (x.y.z)
 * @param latestVersion - Latest semantic version (x.y.z)
 * @returns boolean indicating if there's a minor version update
 * @throws Error if versions are invalid
 */
export const isNewMinorUpdate = (
	currentVersion: VersionInfo,
	latestVersion: VersionInfo
): boolean => {
	return (
		currentVersion.MAJOR === latestVersion.MAJOR && currentVersion.MINOR < latestVersion.MINOR
	);
};

const fetchAndParseLatestVersion = async (): Promise<VersionInfo> => {
	try {
		const version = await VersionApi.getApplicationLastVersion();
		return parseVersion(version.BUILD_VERSION);
	} catch (error) {
		throw new Error("Failed to fetch latest version", { cause: error });
	}
};

export const createCheckVersionInterval = (ctx: {
	onMajorUpdate: (version: VersionInfo) => void;
	onMinorUpdate: (version: VersionInfo) => void;
	currentVersion: string;
}) => {
	let curTimeout: NodeJS.Timeout;
	let currentVersion: VersionInfo;

	try {
		currentVersion = parseVersion(ctx.currentVersion);
	} catch (error) {
		console.error(error);
		return;
	}

	const timeoutHandler = async () => {
		try {
			const latestVersion = await fetchAndParseLatestVersion();
			if (isNewMajorUpdate(currentVersion, latestVersion)) {
				ctx.onMajorUpdate(latestVersion);
			} else if (isNewMinorUpdate(currentVersion, latestVersion)) {
				ctx.onMinorUpdate(latestVersion);
			}
		} catch (error) {
			console.error(error);
		}
		curTimeout = setTimeout(timeoutHandler, 10000);
	};

	const startInterval = () => {
		if (window.location.host === "localhost:1234") {
			return;
		}
		curTimeout = setTimeout(timeoutHandler, 10000);
	};

	const stopInterval = () => {
		clearTimeout(curTimeout);
	};

	return { startInterval, stopInterval };
};
