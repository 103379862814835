import "./global.scss";
import type { Component } from "solid-js";
import { Router, useLocation, useNavigate, useRoutes } from "@solidjs/router";
import { oldRedirectMap, routes } from "../models/routes";
import { ConfigHoc } from "~/solidJs/shared/helpers/HOCS/ConfigHOC";
import { AxiosClientHoc } from "~/solidJs/shared/helpers/HOCS/AxiosClientHoc";
import { SuperTokens } from "~/solidJs/shared/helpers/HOCS/SuperTokens";
import { SuspenseBlock } from "~/solidJs/shared/ui/atoms/SuspenseBlock/SuspenseBlock";
import { QueryClientProvider } from "@tanstack/solid-query";
import { queryClient } from "~/solidJs/shared/api/queryClient";
import { createEffect } from "solid-js";
import { WorkspaceToaster } from "~/solidJs/widgets/WorkspaceToast/WorkspaceToast";
import { restoreUtms } from "~/solidJs/shared/helpers/amplitudeHelpers";

type LayoutTrainPanelApp = {};

export const App: Component<LayoutTrainPanelApp> = (props) => {
	const Routes = useRoutes(routes);

	return (
		<Router>
			<SuspenseBlock size="large">
				<ConfigHoc>
					<AxiosClientHoc>
						<SuperTokens>
							<QueryClientProvider client={queryClient}>
								<SuspenseBlock name="App" size="large">
									<Routes />
									<TrackRedirectToNew />
									<WorkspaceToaster />
								</SuspenseBlock>
							</QueryClientProvider>
						</SuperTokens>
					</AxiosClientHoc>
				</ConfigHoc>
			</SuspenseBlock>
		</Router>
	);
};

const TrackRedirectToNew = () => {
	const navigate = useNavigate();

	const routerLocation = useLocation();
	createEffect(() => {
		const pathUrl = new URL(routerLocation.pathname, window.location.origin);
		if (pathUrl.pathname in oldRedirectMap) {
			const params = new URLSearchParams(restoreUtms());
			navigate(`${oldRedirectMap[pathUrl.pathname]}?${params}`);
		}
	});
	return null;
};
