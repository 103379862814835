import { axiosClient } from "~/js/network/axiosClient";
import { BaseApi } from "../../api/baseApi";
import type { VersionResponse } from "./model";

export class VersionApi extends BaseApi {
	static baseUrl = "/build-version";

	static async getApplicationLastVersion() {
		const { data } = await axiosClient.axiosClient.get<VersionResponse>(this.baseUrl, {
			params: { random: Math.random() },
			baseURL: ""
		});
		return data;
	}
}
